<!--  -->
<template>

  <div class='building'>
    <div class="topPic">
      <h4 class="backgroundText1">体检在医院之外，人人都有云医生</h4>
      <div class="backgroundText2">心狗健康小屋</div>
    </div>

<!--    <div class="picS1" style="margin-top:61px">-->
      <el-carousel :interval="4000"  height="500px" width="1280px" style="margin-top:102px;font-size: 25px">
        <el-carousel-item v-for="item in stationFuncPic" :key="item" >
          <img :src="item.demo" alt="" class='revolve'>
          {{item.txt}}
        </el-carousel-item>

<!--        <el-carousel-item v-for="item in stationFuncTxt" :key="item" >

          <div class="funcText" >{{item.txt}}</div>
          <img :src="item.demo" alt="" class='revolve'>

        </el-carousel-item>-->
      </el-carousel>




  </div>



</template>


<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';



export default {
//import引入的组件需要注入到对象中才能使用



  components: {},
  data() {
//这里存放数据
    return {
      stationFuncPic:[
        {demo:require('@/assets/img/introduction/2.png'),txt: '智能心电血压二合一'},
        {demo:require('@/assets/img/introduction/3.png'),txt: '智能尿检仪'},
        {demo:require('@/assets/img/introduction/7.png'),txt: '智能血氧仪'},
        {demo:require('@/assets/img/introduction/6.png'),txt: '智能血糖仪'},
        {demo:require('@/assets/img/introduction/妇温宝.png'),txt: '妇温宝'},
        {demo:require('@/assets/img/introduction/4.png'),txt: '智能血脂仪'},
        {demo:require('@/assets/img/introduction/8.png'),txt: '智能体脂仪'},
        {demo:require('@/assets/img/introduction/5.png'),txt: '智能三合一测量仪'},
      ],
      /*stationFuncTxt:[
        {txt: '我是云南的'},
        {txt: '云南怒江的'},
        {txt: '怒江泸水市'},
        {txt: '泸水市六库'},
        {txt: '20'},
        {txt: '24'},
        {txt: '28'},
        {txt: '32'},
      ],*/
    };

  },
//监听属性 类似于data概念
  computed: {

    },
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.building{
  background:url(~@/assets/img/stationBack.jpg);
  position: absolute;
  width:1280px;
  height:720px;
  background-size:100% 100%;
}
.backgroundText1 {
  text-align: right;
  font-size: 32px;
  margin-top: 480px;
  margin-right: 150px;
  text-transform: uppercase;
  color: white;
}
.backgroundText2 {
  text-align: right;
  font-size: 75px;
  color: white;
  margin-top: -25px;
  margin-right: 150px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 50px;
  opacity: 0.75;
  line-height: 250px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  width: 1280px;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.revolve{
  width:500px;
  height:500px;
}

.funcText {
  text-align:left;
  font-size: 25px;
  margin-top: 0px;
  margin-right: 0px;
  color: black;
}

.picS1{
  width: 1280px;
}
</style>
